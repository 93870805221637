import React, { useState } from "react"
import { useQuestion } from "@contexts/survey"
import {
  trackInsuranceSepaMandateCreated,
  trackInsuranceSepaMandateFailed,
  trackInsuranceSepaMandateSkipped,
} from "@services/client/events"
import { insuranceAddSepaMandate } from "@services/client/insurance"

import { Button } from "../common/Button"
import { Checkbox } from "../common/Checkbox"
import { TextInput } from "../common/TextInput"

export const InsuranceSepaMandateStep: React.FC = () => {
  const [, , dispatch] = useQuestion()

  //const insuranceFlowVariant = useExperiment("insurance_flow_variant").get("variant", "v1")
  const insuranceFlowVariant = "v2"

  const onConfirm = async (depositor, iban) => {
    await insuranceAddSepaMandate(depositor, iban)
    dispatch({ type: "QUESTION_COMPLETED" })
  }

  const onSkip = async () => {
    dispatch({ type: "QUESTION_COMPLETED" })
  }

  return <InsuranceSepaMandate variant={insuranceFlowVariant} onConfirm={onConfirm} onSkip={onSkip} />
}

export const InsuranceSepaMandate: React.FC<{
  variant: string
  onConfirm: (depositor: string, iban: string) => Promise<void>
  onSkip: () => void
}> = ({ variant, onConfirm, onSkip }) => {
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({
    depositor: "",
    depositorError: "",
    iban: "",
    ibanError: "",
    termsChecked: false,
    termsError: "",
  })

  const onChange = (field: string, value: string | boolean) => {
    setForm({
      ...form,
      [field]: value,
      [`${field}Error`]: "",
    })
  }

  const handleOnSubmit = async () => {
    const { depositor, iban, termsChecked } = form
    const depositorError = depositor ? "" : "Kontoinhaber angeben"
    const ibanError = iban ? "" : "IBAN angeben"
    const termsError = termsChecked ? "" : "Bestätigung erforderlich"

    if (depositorError || ibanError || termsError) {
      setForm({
        ...form,
        depositorError,
        ibanError,
        termsError,
      })
      return
    }

    setLoading(true)
    try {
      await onConfirm(depositor, iban)
      trackInsuranceSepaMandateCreated()
    } catch {
      trackInsuranceSepaMandateFailed()

      setForm({
        ...form,
        ibanError: "Fehler beim Speichern der Daten",
      })
      setLoading(false)
    }
  }

  const handleOnSkip = async () => {
    trackInsuranceSepaMandateSkipped()
    onSkip()
  }

  return (
    <div className="mx-auto flex w-full max-w-xl flex-col justify-center px-4">
      <div>
        <h3 className="text-left text-2xl font-bold text-dark1 mb-2">
          {variant === "v1" ? "Deine Daten sind unvollständig" : "Einfach & sicher per SEPA-Lastschrift bezahlen"}
        </h3>
        <p className="text-left text-dark3">
          {variant === "v1"
            ? "Bitte ergänze deine Kontoverbindung, damit deine Daten vollständig für die Krankenkassenabwicklung vorliegen."
            : " Erteile uns ein SEPA-Mandat, und wir buchen den Betrag sicher von deinem Konto ab – keine manuelle Überweisung nötig."}
        </p>
      </div>
      <div className="mt-6">
        <TextInput
          label="Kontoinhaber"
          value={form.depositor}
          onChange={(value) => onChange("depositor", value)}
          error={form.depositorError}
          disabled={loading}
        />
        <TextInput
          label="IBAN"
          value={form.iban}
          onChange={(value) => onChange("iban", value)}
          error={form.ibanError}
          disabled={loading}
        />
        <Checkbox
          label={
            <span className="text-sm text-dark3 font-normal">
              {variant === "v1"
                ? "Ich autorisiere Fastic zur sicheren Verwendung meiner Kontodaten, um das Erstattungsformular für meine Krankenkasse zu erstellen und den Betrag per SEPA-Lastschrift einzuziehen."
                : "Ich bestätige, dass Fastic den Kursbetrag per SEPA-Lastschrift abbuchen und mein Erstattungsformular für die Krankenkasse vorbereiten darf."}
            </span>
          }
          checked={form.termsChecked}
          onChange={(checked) => onChange("termsChecked", checked)}
          error={form.termsError}
          disabled={loading}
        />
      </div>

      <div className="mt-6 flex flex-col space-y-2">
        <Button primary label={"Speichern"} onClick={handleOnSubmit} processing={loading} />
        <Button label={"Überspringen"} onClick={handleOnSkip} disabled={loading} />
      </div>
      {/* <Button type="link" label={t("skip")} onClick={() => router.push("/next-step")} /> */}

      <div className="mt-4 w-full px-2 text-center text-xs text-dark4">
        <span>
          Hinweise zur Verwendung deiner Daten findest du in unseren{" "}
          <a className="underline" target="_blank" href="https://fastic.com/de/privacy">
            Datenschutzrichtlinien
          </a>
        </span>
      </div>
    </div>
  )
}
