import React, { useState } from "react"
import Image from "next/image"
import { Button } from "@components/common/Button"
import { IconListItem } from "@components/common/IconListItem"
import { SurveyStateInitialized } from "@contexts/survey/survey"
import {
  trackInsuranceModalClose,
  trackInsurancePaywallCTAClick,
  trackInsurancePaywallView,
  trackInsuranceSelected,
} from "@services/client/events"

import { useQuestion, useSurvey } from "../../contexts/survey"
import { useTranslation } from "../../i18n"
import { Insurance } from "../../types/insurance"
import InsuranceModal from "./InsuranceModal"

const FaqItem: React.FC<{
  title: string
  children?: React.ReactNode
}> = ({ title, children }) => (
  <details className="group rounded-lg bg-white p-4">
    <summary className="flex cursor-pointer items-center justify-between font-semibold text-dark1">
      {title}
      <svg
        className="size-5 transition-transform group-open:rotate-180"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
      </svg>
    </summary>
    <div className="mt-2 text-sm text-dark3">{children}</div>
  </details>
)

export const InsurancePaywallSurveyStep: React.FC = () => {
  const [, , dispatch] = useQuestion()
  const [surveyState] = useSurvey()

  const isTiktok = (surveyState as SurveyStateInitialized).theme === "tiktok"
  //const insuranceFlowVariant = useExperiment("insurance_flow_variant").get("variant", "v1")
  const insuranceFlowVariant = "v2"

  return (
    <InsurancePaywall
      variant={insuranceFlowVariant}
      isTiktok={isTiktok}
      onInsuranceSelected={(insurance) => {
        dispatch({
          type: "QUESTION_ANSWER_CHANGED",
          name: "insurance",
          value: insurance.name,
        })

        // Move to next step
        dispatch({ type: "QUESTION_COMPLETED" })
      }}
    />
  )
}

export const InsurancePaywall: React.FC<{
  isTiktok: boolean
  onInsuranceSelected: (insurance: Insurance) => void
  variant: string
}> = ({ isTiktok, onInsuranceSelected, variant }) => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)

  // Add useEffect to track page view
  React.useEffect(() => {
    trackInsurancePaywallView()
  }, [])

  const handleCTAClick = () => {
    trackInsurancePaywallCTAClick()
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    trackInsuranceModalClose()
    setIsModalOpen(false)
  }

  const handleInsuranceSelect = (insurance: Insurance) => {
    setIsModalOpen(false)
    trackInsuranceSelected(insurance.name)
    onInsuranceSelected(insurance)
  }

  return (
    <div className="mx-auto flex w-full  max-w-xl flex-col items-center overflow-hidden">
      <div className="relative mb-6 w-full">
        <Image
          src="/images/paywall/Hero-Kurs.svg"
          alt={t("insurance_hero")}
          width={390}
          height={278}
          className="w-full"
        />
      </div>

      <div className="flex w-full max-w-xl flex-col items-center px-4">
        {variant === "v1" && (
          <div className="mb-6 space-y-2 text-center">
            <h1 className="text-2xl font-extrabold text-dark1">
              {isTiktok ? "Gratis zu gesundem Gewicht!" : "Kostenlos Abnehmen"}
            </h1>
            <p className="text-lg font-semibold text-dark1">1 Jahr Fastic PLUS gratis durch deine Krankenkasse</p>
          </div>
        )}

        {variant === "v2" && (
          <div className="mb-6 space-y-2 text-center">
            <h1 className="text-2xl font-extrabold text-dark1">Online-Präventionskurs</h1>
            <h1 className="text-2xl font-extrabold text-dark1">+ 12 Monate Fastic Plus kostenlos</h1>
            <p className="text-lg font-semibold text-dark1">Deine Krankenkasse bezahlt bis zu 100%</p>
          </div>
        )}

        <Button
          primary
          label="Weiter"
          className="mb-10 w-full animate-pulsar"
          onClick={handleCTAClick}
          customBg={{ default: "bg-green1", hover: "hover:bg-green1" }}
        />

        <div className="mb-10 w-full text-left">
          <h2 className="mb-4 text-lg font-semibold text-dark1">Was erwartet dich?</h2>
          {variant === "v1" && (
            <div className="space-y-10 rounded-2xl bg-white p-4">
              <IconListItem icon="fastic" text="12 Monate Fastic Plus gratis und ohne Abo nutzen" />
              <IconListItem icon="food" text="Lerne in 8 Wochen die Grundlagen gesunder Ernährung." />
              <IconListItem
                icon="money"
                text="Erhalte dein Geld am Ende des Kurses ganz unkompliziert von deiner Krankenkasse zurück"
              />
            </div>
          )}
          {variant === "v2" && (
            <div className="space-y-10 rounded-2xl bg-white p-4">
              <IconListItem icon="fastic" text="12 Monate Fastic Plus gratis und ohne Abo nutzen" />
              <IconListItem icon="food" text="Lerne in 8 Wochen die Grundlagen gesunder Ernährung." />
              <IconListItem
                icon="money"
                text="Erhalte dein Geld am Ende des Kurses ganz unkompliziert von deiner Krankenkasse zurück"
              />
            </div>
          )}
        </div>

        <div className="mb-10 w-full rounded-2xl border bg-white p-4 shadow-lg">
          <h2 className="mb-8 text-center text-xl font-semibold text-dark1">
            Kein Abo und für dich 100% kostenlos! Darin enthalten:
          </h2>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <span className="text-dark3">Präventionskurs:</span>
              <span className="rounded-full bg-red-100 px-3 py-1 text-lg font-bold text-dark1 line-through decoration-red-500 decoration-2">
                119 €
              </span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-dark3">12 Monate Fastic Plus</span>
              <span className="rounded-full bg-red-100 px-3 py-1 text-lg font-bold text-dark1 line-through decoration-red-500 decoration-2">
                79,99 €
              </span>
            </div>
            <div className="flex items-center justify-between border-t-2 border-dark1 pt-4">
              <span className="font-bold text-dark1">Dein Preis</span>
              <span className="rounded-full bg-green-200 px-4 py-1 text-lg font-bold text-dark1">0 €</span>
            </div>
          </div>
        </div>

        <Button
          primary
          label="Weiter"
          className="mb-10 w-full animate-pulsar"
          onClick={handleCTAClick}
          customBg={{ default: "bg-green1", hover: "hover:bg-green1" }}
        />

        <div className="w-full space-y-10">
          <div>
            <h2 className="mb-4 text-lg font-semibold text-dark1">Und so funktioniert es:</h2>

            <div className="space-y-8">
              <div className="flex items-center gap-4">
                <div className="rounded-lg bg-green-50 p-2 flex-none">
                  <Image src="/images/paywall/icon_register.svg" alt="" width={60} height={60} />
                </div>
                <div className="grow">
                  {variant === "v1" && (
                    <>
                      <h3 className="font-semibold text-dark1">Anmelden</h3>
                      <p className="text-sm text-gray-500">
                        Einfach anmelden und direkt mit der Fastic App und dem exklusiven Kurs starten.
                      </p>
                    </>
                  )}
                  {variant === "v2" && (
                    <>
                      <h3 className="font-semibold text-dark1">Jetzt starten & Kurs beginnen</h3>
                      <p className="text-sm text-gray-500">
                        Melde dich bequem an, beginne den Kurs und genieße Fastic Plus für 12 Monate ohne Abo.{" "}
                        <strong>Jede Woche ohne den Kurs ist eine verpasste Chance auf deine Erstattung!</strong>
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-4">
                <div className="rounded-lg bg-green-50 p-2 flex-none">
                  <Image src="/images/paywall/icon_refund.svg" alt="" width={60} height={60} />
                </div>
                <div className="grow">
                  {variant === "v1" && (
                    <>
                      <h3 className="font-semibold text-dark1">Geld zurück erhalten</h3>
                      <p className="text-sm text-gray-500">
                        Nach 8 Wochen kannst Du das Geld von der Krankenkasse zurückerhalten*
                      </p>
                    </>
                  )}
                  {variant === "v2" && (
                    <>
                      <h3 className="font-semibold text-dark1">Rechnung erhalten & flexibel starten</h3>
                      <p className="text-sm text-gray-500">
                        Nach deiner Anmeldung erhältst du die Rechnung, die du zunächst selbst begleichst – aber du
                        entscheidest, wann du mit dem Kurs beginnst. <strong>Maximale Freiheit für dich!</strong>
                      </p>
                    </>
                  )}
                </div>
              </div>
              {variant === "v2" && (
                <div className="flex items-center gap-4">
                  <div className="rounded-lg bg-green-50 p-2 flex-none">
                    <Image src="/images/paywall/icon_money.svg" alt="" width={60} height={60} />
                  </div>
                  <div className="grow">
                    <h3 className="font-semibold text-dark1">Bis zu 100 % Erstattung durch deine Krankenkasse</h3>
                    <p className="text-sm text-gray-500">
                      Nach Abschluss des Kurses reichst du die Rechnung gemeinsam mit deinem Teilnehmerzertifikat bei
                      deiner Krankenkasse ein – und bekommst dein Geld zurück.{" "}
                      <strong>Tausende haben es bereits getan – sicher dir jetzt deine Rückerstattung!</strong>
                    </p>
                  </div>
                </div>
              )}
            </div>

            {variant === "v1" && (
              <p className="mt-4 text-sm text-gray-400">
                *Bei Problemen helfen wir Dir gerne mit der Krankenkassenabrechnung.
              </p>
            )}
            {variant === "v2" && (
              <p className="mt-6 text-base text-dark1 font-bold text-center">
                ✨ Starte heute und sichere dir dein Geld zurück!
              </p>
            )}
          </div>

          <div>
            <h2 className="mb-6 text-lg font-semibold text-dark1">Über die Autorin:</h2>
            <div className="flex flex-col items-start gap-4 rounded-2xl bg-white p-4">
              <div className="flex flex-row items-center gap-4">
                <Image
                  src="/images/insurance/luisa.png"
                  alt="Luisa Beerling"
                  width={64}
                  height={64}
                  className="rounded-full"
                />
                <div className="flex flex-col">
                  <h3 className="text-lg font-semibold text-dark1">Luisa Beerling</h3>
                  <p className="mb-2 text-gray-500">Ernährungsberaterin</p>
                </div>
              </div>
              <div>
                <p className="text-sm text-dark3">
                  Hallo! Ich bin Luisa, studierte Ernährungsberaterin und werde dich durch den Kurs geleiten. Die Themen
                  Ernährung, Gesundheit und Fitness liegen mir sehr am Herzen. Ich möchte Menschen für diese Themen
                  begeistern, sie zu einem gesunden und aktiven Lebensstil motivieren und so langfristig zu mehr
                  Lebensfreude und Wohlbefinden verhelfen. Ich lade dich herzlich ein mit mir diesen Weg zu gehen.
                  Schön, dass du da bist!
                </p>
              </div>
            </div>
          </div>
          <div className="mb-6 flex items-center justify-center">
            <div className="rounded-2xl bg-green-50 p-4">
              <div className="flex flex-col gap-4">
                <h2 className="text-xl font-bold text-dark1">Zertifiziert nach §20 SGB V</h2>
                <p className="text-sm text-dark3">
                  Der Online-Kurs ist zertifiziert durch die zentrale Prüfstelle Prävention - Eine
                  Kooperationsgemeinschaft gesetzlicher Krankenkassen zur Zertifizierung von Präventionskursen.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <Button
              primary
              label="Weiter"
              className="mb-10 w-full animate-pulsar"
              onClick={handleCTAClick}
              customBg={{ default: "bg-green1", hover: "hover:bg-green1" }}
            />
            <p className="px-4 text-center text-sm font-bold text-dark4">100% Geld zurück Garantie!</p>
            {variant === "v1" && (
              <p className="px-4 text-center text-sm text-dark4">
                Nach 8 Wochen, bekommst du von deiner Krankenkasse das Geld zurück. Sollte deine Krankenkasse in der
                Zwischenzeit etwas ändern, erstatten wir die Differenz!
              </p>
            )}
            {variant === "v2" && (
              <p className="px-4 text-center text-sm text-dark4">
                Nach Abschluss des Kurses reichst du die Rechnung gemeinsam mit deinem Teilnehmerzertifikat bei deiner
                Krankenkasse ein – und bekommst dein Geld zurück. Sollte deine Krankenkasse in der Zwischenzeit etwas
                ändern, erstatten wir die Differenz!
              </p>
            )}
          </div>

          <div className="mt-8 space-y-4">
            <h2 className="mb-2 text-xl font-semibold text-dark1">Fragen zur Erstattung:</h2>
            <div className="space-y-2">
              <FaqItem title="Erstattet auch meine Krankenkasse die Kursgebühren?">
                Der Kurs ist nach §20 Abs. 1 SGB V zertifiziert. Das bedeutet, dass gesetzliche Krankenkassen diesen
                Kurs in der Regel zu 75 % - 100 % bezuschussen. Suche in unserem Krankenkassen-Erstattungsrechner in der
                App nach deiner Krankenkasse und erfahre, wie viel dir deine Krankenkasse erstattet.
              </FaqItem>

              <FaqItem title="Wie bekomme ich mein Geld zurück?">
                Grundlage für die Rückerstattung der Kosten durch die Krankenkassen ist deine Teilnahme-Bescheinigung
                über den erfolgreich absolvierten Kurs. Diese bekommst du ganz bequem nach dem Kurs von uns zugesendet
                und kannst diese bei der Krankenkasse einreichen. Diese überweist dir dann den Betrag auf dein Konto.
              </FaqItem>
              <h2 className="text-xl font-semibold text-dark1">Fragen zum Kurs:</h2>
              <FaqItem title="Wie erhalte ich den Kurszugang?">
                Nach dem Kauf erhältst du eine E-Mail mit einem Link und deinen Zugangsdaten zum Kurs.
              </FaqItem>

              <FaqItem title="Eignet der Kurs sich auch für Nicht-Vegetarier/Veganer?">
                Der Kurs ist grundsätzlich für alle Ernährungsformen geeignet. Er enthält sowohl rein pflanzliche, als
                auch vegetarische Gerichte und welche mit Fleisch und Fisch. Auch die jeweiligen Themen werden
                ausführlich besprochen. Wenn du dich vegetarisch oder vegan ernährst, kannst du dir ebenso die
                vermittelten Ernährungsgrundlagen und die Rezepte kannst du relativ einfach umwandeln.
              </FaqItem>

              <FaqItem title="Wann kann ich mit dem Kurs beginnen?">
                Sobald die Buchung des Online-Präventionskurses abgeschlossen ist, kannst du direkt loslegen, völlig
                zeit- und ortsunabhängig.
              </FaqItem>

              <FaqItem title="Mit welchen Geräten kann ich auf den Kurs zugreifen?">
                Sobald die Buchung des Online-Präventionskurses abgeschlossen ist, kannst du direkt loslegen, völlig
                zeit- und ortsunabhängig.
              </FaqItem>

              <FaqItem title="Gibt es einen technischen Ansprechpartner?">
                Als Kurs-Teilnehmer hast du ebenfalls die Möglichkeit, uns bei technischen Schwierigkeiten zu
                kontaktieren.
              </FaqItem>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <Button
              primary
              label="Weiter"
              className="mb-10 w-full animate-pulsar"
              onClick={handleCTAClick}
              customBg={{ default: "bg-green1", hover: "hover:bg-green1" }}
            />
          </div>
        </div>
      </div>

      <InsuranceModal
        variant={variant}
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSelect={handleInsuranceSelect}
      />
    </div>
  )
}
