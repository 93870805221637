import React, { useState } from "react"
import { SurveyStateInitialized } from "@contexts/survey/survey"
import { insuranceCheckout } from "@services/client/insurance"

import { useUser } from "../../contexts/auth"
import { useQuestion, useSurvey } from "../../contexts/survey"
import { useTranslation } from "../../i18n"
import { updateUser, UpdateUser } from "../../services/client/api"
import {
  trackCompleteInsuranceCheckout,
  trackInitiateInsuranceCheckout,
  trackInsuranceCheckoutError,
  trackInsuranceConfirmPaymentAbort,
  trackInsuranceConfirmPaymentOpened,
} from "../../services/client/events"
import { germanInsurances, Insurance } from "../../types/insurance"
import { validateCity, validateEmail, validateName, validateStreetName, validateZipCode } from "../../utils/validation"
import { Button } from "../common/Button"
import { Checkbox } from "../common/Checkbox"
import { TextInput } from "../common/TextInput"
import AddressAutoCompleteTextInput, { Address } from "./AddressAutoCompleteTextInput"
import InsuranceConfirmPaymentModal from "./InsuranceConfirmPaymentModal"

export const SignUpInsuranceSurveyStep: React.FC = () => {
  const [, answers, dispatch] = useQuestion()
  const [surveyState] = useSurvey()
  const user = useUser()

  // We need to skip payment tracking for the no_loader_track funnel
  // to don't share revenue with the ad network. We will send registration completed events instead.
  const skipTracking = (surveyState as SurveyStateInitialized).funnel === "no_loader_track"
  const confirmationEnabled = true

  const selectedInsurance = germanInsurances.find((i) => i.name === answers.insurance.toString())

  //const insuranceFlowVariant = useExperiment("insurance_flow_variant").get("variant", "v1")
  const insuranceFlowVariant = "v2"

  const onCheckout = async (form: CheckoutForm) => {
    // Track initiation of insurance checkout with price
    trackInitiateInsuranceCheckout(
      user.id,
      selectedInsurance.name,
      "KU-ER-GSP6FU",
      selectedInsurance?.price || 0,
      skipTracking
    )

    // Update user data
    const updateData: UpdateUser = {
      name: `${form.name}`,
      survey: { answers: answers },
    }

    const result = await updateUser(updateData)

    if (result.error) {
      trackInsuranceCheckoutError({
        uid: user.id,
        insuranceProvider: selectedInsurance.name,
        courseId: "KU-ER-GSP6FU",
        error: result.error.message,
      })

      throw new Error(result.error.message)
    }

    // Process insurance checkout
    await insuranceCheckout(
      {
        firstname: form.name,
        lastname: form.surname,
        email: form.email,
        street: form.streetName,
        streetNumber: form.houseNumber,
        city: form.city,
        zipCode: form.zipCode,
        country: "DE",
      },
      selectedInsurance.name,
      "KU-ER-GSP6FU"
    )

    // Track successful completion
    trackCompleteInsuranceCheckout({
      uid: user.id,
      email: form.email,
      insuranceProvider: selectedInsurance.name,
      courseId: "KU-ER-GSP6FU",
      price: selectedInsurance?.price || 0,
      skipFB: skipTracking,
      clv_usd: ((selectedInsurance?.price || 0) / 1.19) * 0.68 * 1.05,
    })

    dispatch({ type: "QUESTION_COMPLETED" })
  }

  return (
    <SignUpInsurance
      variant={insuranceFlowVariant}
      name={user.name}
      email={user.email}
      confirmationEnabled={confirmationEnabled}
      selectedInsurance={selectedInsurance}
      onCheckout={onCheckout}
    />
  )
}

export type CheckoutForm = {
  name?: string
  nameError?: string
  surname?: string
  surnameError?: string
  email?: string
  emailError?: string
  streetName?: string
  streetNameError?: string
  houseNumber?: string
  houseNumberError?: string
  zipCode?: string
  zipCodeError?: string
  city?: string
  cityError?: string
  acceptTerms: boolean
  acceptTermsError?: string
}

export const SignUpInsurance: React.FC<{
  name?: string
  email?: string
  selectedInsurance: Insurance
  confirmationEnabled: boolean
  variant: string
  onCheckout: (form: CheckoutForm) => Promise<void>
}> = ({ name, email, selectedInsurance, confirmationEnabled, onCheckout, variant }) => {
  const { t } = useTranslation()
  const [processing, setProcessing] = useState(false)
  const [isConfirmPaymentOpen, setIsConfirmPaymentOpen] = useState(false)

  const [form, setForm] = useState<CheckoutForm>({
    name: name,
    email: email,
    surname: "",
    streetName: "",
    houseNumber: "",
    zipCode: "",
    city: "",
    acceptTerms: false,
  })

  const onNameChange = (value: string) => {
    setForm({
      ...form,
      name: value.trim(),
      nameError: null,
    })
  }

  const onSurnameChange = (value: string) => {
    setForm({
      ...form,
      surname: value.trim(),
      surnameError: null,
    })
  }

  const onEmailChange = (value: string) => {
    setForm({
      ...form,
      email: value.trim(),
      emailError: null,
    })
  }

  const onPlaceSelected = (value: Address) => {
    let update = { ...form }
    if (value.streetAddress) {
      update = { ...update, streetName: value.streetAddress.trim(), streetNameError: null }
    }
    if (value.streetNumber) {
      update = { ...update, houseNumber: value.streetNumber.trim(), houseNumberError: null }
    }
    if (value.city) {
      update = { ...update, city: value.city.trim(), cityError: null }
    }
    if (value.postalCode) {
      update = { ...update, zipCode: value.postalCode.trim(), zipCodeError: null }
    }
    setForm(update)
  }

  const onStreetNameChange = (value: string) => {
    setForm({
      ...form,
      streetName: value.trim(),
      streetNameError: null,
    })
  }

  const onHouseNumberChange = (value: string) => {
    setForm({
      ...form,
      houseNumber: value.trim(),
      houseNumberError: null,
    })
  }

  const onZipCodeChange = (value: string) => {
    setForm({
      ...form,
      zipCode: value.trim(),
      zipCodeError: null,
    })
  }

  const onCityChange = (value: string) => {
    setForm({
      ...form,
      city: value.trim(),
      cityError: null,
    })
  }

  const onAcceptTermsChange = (checked: boolean) => {
    setForm({
      ...form,
      acceptTerms: checked,
      acceptTermsError: null,
    })
  }

  const onInsuranceCheckout = async () => {
    try {
      await onCheckout(form)
    } catch (error) {
      setForm({
        ...form,
        emailError: error.message,
      })
    } finally {
      setProcessing(false)
    }
  }

  const onPaymentConfirmed = () => {
    setIsConfirmPaymentOpen(false)
    onInsuranceCheckout()
  }

  const onPaymentAborted = () => {
    trackInsuranceConfirmPaymentAbort()
    setIsConfirmPaymentOpen(false)
    setProcessing(false)
  }

  const onContinue = async () => {
    setProcessing(true)
    const [
      nameError,
      surnameError,
      emailError,
      streetNameError,
      houseNumberError,
      zipCodeError,
      cityError,
      acceptTermsError,
    ] = [
      validateName(form.name),
      validateName(form.surname),
      validateEmail(form.email),
      validateStreetName(form.streetName),
      form.houseNumber ? null : "common:errors.house_number.min",
      validateZipCode(form.zipCode),
      validateCity(form.city),
      form.acceptTerms ? null : "common:errors.terms.accept",
    ]

    if (
      nameError ||
      surnameError ||
      emailError ||
      streetNameError ||
      houseNumberError ||
      zipCodeError ||
      cityError ||
      acceptTermsError
    ) {
      setForm({
        ...form,
        nameError,
        surnameError,
        emailError,
        streetNameError,
        houseNumberError,
        zipCodeError,
        cityError,
        acceptTermsError,
      })
      setProcessing(false)
    } else {
      if (confirmationEnabled) {
        trackInsuranceConfirmPaymentOpened()
        setIsConfirmPaymentOpen(true)
      } else {
        onInsuranceCheckout()
      }
    }
  }

  return (
    <>
      <div className="mx-auto flex w-full max-w-xl flex-col justify-center px-4">
        <div className="grow">
          <h3 className="text-left text-2xl font-bold text-dark1">
            {variant === "v1" ? "Fastic Plus aktivieren" : "Fast geschafft! Jetzt Kursplatz sichern!"}
          </h3>
          <p className="text-left text-dark3 mt-2">
            {variant === "v1"
              ? "Trage deine Daten ein und erhalte Fastic Plus und deinen Gesundheitskurs für 12 Monate."
              : "Für Deine Anmeldung benötigen wir Deine vollständigen Kontaktdaten. Diese werden für die Erstellung Deiner Krankenkassenbescheinigung und Rechnung benötigt."}
          </p>
        </div>
      </div>
      <div className="max-w-xl w-full mx-auto px-4 mt-7">
        <TextInput
          type="email"
          label={t("survey:signup.your_email")}
          value={form.email}
          error={form.emailError && t(form.emailError)}
          onChange={onEmailChange}
          disabled={processing}
        />
        <div className="flex gap-4">
          <TextInput
            type="text"
            label={"Vorname"}
            value={form.name}
            onChange={onNameChange}
            error={form.nameError && t(form.nameError)}
            disabled={processing}
            containerClass="w-full"
          />
          <TextInput
            type="text"
            label={"Nachname"}
            value={form.surname}
            onChange={onSurnameChange}
            error={form.surnameError && t(form.surnameError)}
            disabled={processing}
            containerClass="w-full"
          />
        </div>
        <div className="flex gap-4">
          <AddressAutoCompleteTextInput
            label={t("survey:registration.street_name")}
            value={form.streetName}
            onChange={onStreetNameChange}
            onPlaceSelected={onPlaceSelected}
            error={form.streetNameError && t(form.streetNameError)}
            disabled={processing}
            country="DE"
            language="de"
          />
          <TextInput
            type="text"
            label={t("survey:registration.house_number")}
            value={form.houseNumber}
            onChange={onHouseNumberChange}
            error={form.houseNumberError && t(form.houseNumberError)}
            disabled={processing}
            containerClass="w-1/4"
          />
        </div>
        <div className="flex gap-4">
          <TextInput
            type="text"
            label={t("survey:registration.zip_code")}
            value={form.zipCode}
            onChange={onZipCodeChange}
            error={form.zipCodeError && t(form.zipCodeError)}
            disabled={processing}
            containerClass="w-2/5"
          />
          <TextInput
            type="text"
            label={t("survey:registration.city")}
            value={form.city}
            onChange={onCityChange}
            error={form.cityError && t(form.cityError)}
            disabled={processing}
            containerClass="w-full"
          />
        </div>

        <div className="mb-10">
          <Checkbox
            label={
              <span className="text-sm text-dark3">
                Ich akzeptiere die geltenden{" "}
                <a className="underline" target="_blank" href="https://fastic.com/de/terms">
                  AGBs
                </a>{" "}
                inklusive der Widerrufsbelehrung.
              </span>
            }
            checked={form.acceptTerms}
            onChange={onAcceptTermsChange}
            error={form.acceptTermsError && t(form.acceptTermsError)}
            disabled={processing}
          />
        </div>
        <div>
          <Button
            processing={processing}
            primary
            label={t("common:controls.next")}
            className="w-full"
            onClick={processing ? null : onContinue}
          />
        </div>
        <div className="mt-4 w-full px-2 text-center text-xs text-dark4">
          <p className="mb-2">
            Ich habe zur Kenntnis genommen, dass ich die Rückerstattung durch Einsenden der Teilnahmebestätigung und
            Rechnung an meine Krankenkasse erhalten kann.
          </p>
          <span>
            Hinweise zur Verwendung deiner Daten findest du in unseren{" "}
            <a className="underline" target="_blank" href="https://fastic.com/de/privacy">
              Datenschutzrichtlinien
            </a>
          </span>
        </div>
      </div>
      <InsuranceConfirmPaymentModal
        variant={variant}
        isOpen={isConfirmPaymentOpen}
        insurance={selectedInsurance}
        onClose={onPaymentAborted}
        onConfirm={onPaymentConfirmed}
      />
    </>
  )
}
